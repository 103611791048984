import styled from "@emotion/styled"
import React from "react"
import Global from "../styles/global"
import Card from "../components/card"
import Typography from "../components/typography"
import { BigPillButton } from "../components/button"
import Link from "../components/link"
import { trackEvent } from "../utils/trackEvent"
import SEO from "../components/seo"
import { BackTo } from "../components/backTo"

const ContentWrapper = styled.div`
  margin: 0 auto;
  margin-top: 80px;
`

const ROI = () => {
  return (
    <>
      <Global bg="primary" />
      <BackTo linkTo="roi" />
      <SEO title="ROI Calculator" enableCookiebot />
      <ContentWrapper>
        <Card center variant="primary">
          <Typography variant="h1" as="h1">
            How much ROI can you drive with Mavenoid?
          </Typography>
          <Typography
            variant="h5"
            as="h2"
            style={{
              marginTop: "2rem",
              marginBottom: "5em",
              maxWidth: "800px",
              whiteSpace: "break-spaces",
            }}
          >
            Use the ROI calculator to estimate how much your company can save
            with product support automation
          </Typography>
          <BigPillButton
            to="/roi-new/inputs"
            as={Link}
            onClick={() =>
              trackEvent({
                category: "view_item",
                label: "start roi calculator",
                siteSection: "roi_start",
              })
            }
            state={{ step: "ROI_INPUTS" }}
            variant="white"
            style={{ height: "90px", outline: "none" }}
          >
            Calculate my ROI
          </BigPillButton>
        </Card>
      </ContentWrapper>
    </>
  )
}

export default ROI
